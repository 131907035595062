import { Injectable } from '@angular/core';
import {
  Scan,
  GitHubUserQuery,
  GitLabUserQuery,
  BitbucketUserQuery,
  SnippetQuery,
  ScanWorkflowRequest,
  ScanQuery,
} from '@app/models';
import {
  IgnoredFiles,
  IgnoredFilesRequestInput,
  IgnoredFileSettingQuery,
} from '@app/models/ignored-files';
import { CoreGraphQLService } from '@app/services/core/core-graphql.service';
import {
  ApolloQueryResult,
  FetchPolicy,
  WatchQueryFetchPolicy,
} from 'apollo-client';
import gql from 'graphql-tag';
import { Observable } from 'rxjs';

@Injectable()
export class ScanService {
  constructor(private coreGraphQLService: CoreGraphQLService) {}

  getScanWithMetrics(scanId: string) {
    return this.coreGraphQLService.coreGQLReqWithQuery<{ scan: Scan }>(
      gql`
            query {
                scan(scanId: "${scanId}") {
                    scanId
                    projectId
                    entityId
                    branch
                    tag
                    version
                    versionHash
                    created
                    status
                    errorMsg
                    log
                    componentCount
                    licenseCount
                    vulnerabilityCount
                    
                    components_audit_count
                    auditScanAssetsCount
                    
                    scanMetricsSummary(isComposite: true) {
                        componentCountMetrics {
                            vulnerableComponents
                            riskyLicenses
                            totalCount
                        }
                        
                        vulnerabilityMetrics {
                            critical
                            high
                            medium
                            low
                            info
                        }
                        licenseMetrics {
                            copyleftStrong
                            copyleftWeak
                            copyleftPartial
                            copyleftLimited
                            copyleft
                            custom
                            dual
                            permissive
                            proprietary
                            proprietaryFree
                        }
                        supplyChainMetrics {
                            risk
                            quality
                        }
                        assetMetrics {
                            embedded
                            openSource
                            unique
                        }
                    }
                }
            }
          `,
      'no-cache'
    );
  }

  getScanUpdateVerificationsData(
    scanId: string
  ): Observable<ApolloQueryResult<any>> {
    return this.coreGraphQLService.coreGQLReqWithQuery<any>(
      gql`
            query {
                scan(scanId: "${scanId}") {
                  updateVerifications {
                    edges {
                      node {
                        apiTestVerificationStatus
                        apiTests
                        branchName
                        buildSystem
                        buildVerificationStatus
                        created
                        fixVersion
                        integrationTest
                        integrationTestsVerificationStatus
                        name
                        namespace
                        orgId
                        prNumber
                        prUrl
                        scanId
                        scheme
                        type
                        unitTest
                        unitTestsVerificationStatus
                        version
                        mergeStatus
                        vulnsBeforeFix
                        vulnsAfterFix
                      }
                    }
                  }
                }
            }
          `,
      'no-cache'
    );
  }

  // todo: ref: move to separate service
  /** Request GitHub repos data from backend */
  getGitHubUser(fetchPolicy?: WatchQueryFetchPolicy) {
    return this.coreGraphQLService.coreGQLReq<GitHubUserQuery>(
      gql`
        query {
          gitHubUser {
            id
            isSiteAdmin
            avatarUrl
            email
            location
            name
            company
            url
            login
            organizations {
              totalCount
              edges {
                node {
                  ... on Organization {
                    id
                    name
                    avatarUrl
                    repositories {
                      totalCount
                      edges {
                        node {
                          ... on Repository {
                            id
                            name
                            archived
                            fork
                            private
                            resourcePath
                            sshUrl
                            url
                            primaryLanguage {
                              color
                              name
                            }
                            defaultBranchRef {
                              name
                              target {
                                oid
                              }
                            }
                            refs {
                              edges {
                                node {
                                  ... on Ref {
                                    id
                                    name
                                    target {
                                      oid
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            repositories {
              totalCount
              edges {
                node {
                  ... on Repository {
                    id
                    name
                    archived
                    fork
                    private
                    resourcePath
                    sshUrl
                    url
                    primaryLanguage {
                      color
                      name
                    }
                    defaultBranchRef {
                      name
                    }
                    refs {
                      edges {
                        node {
                          ... on Ref {
                            id
                            name
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      fetchPolicy
    );
  }

  /** Request gitlab repos data from backend */
  getGitLabUser(premise: boolean, fetchPolicy?: WatchQueryFetchPolicy) {
    let queryStart = 'gitLabUser';
    if (premise) {
      queryStart = 'gitLabUser(premise: true)';
    }
    return this.coreGraphQLService.coreGQLReq<GitLabUserQuery>(
      gql`
            query {
                ${queryStart} {
                  id,
                  avatarUrl,
                  email,
                  name,
                  username,
                  gitLabProjects {
                    id,
                    name,
                    fullPath,
                    description,
                    httpUrlToRepo,
                    sshUrlToRepo,
                    path,
                    webUrl,
                    archived,
                    createdAt,
                    repository {
                        rootRef,
                        exists
                    }
                  }
                }
            }
        `,
      fetchPolicy
    );
  }

  /** Request bitbucket repos data from backend */
  getBitbucketUser(fetchPolicy?: WatchQueryFetchPolicy) {
    return this.coreGraphQLService.coreGQLReq<BitbucketUserQuery>(
      gql`
        query {
          bitbucketUser {
            id
            name
            username
            state
            email
            avatarUrl
            webUrl
            organization
            bitBucketRepositories {
              name
              fullName
              url
              sshUrl
              owner
              createdOn
              description
              language
              mainBranch
              branches
            }
          }
        }
      `,
      fetchPolicy
    );
  }

  getSnippetMatches(snippetText: string, languageType: string) {
    return this.coreGraphQLService.coreGQLReq<SnippetQuery>(
      gql`
        query ($snippetText: String, $languageType: String) {
          snippetMatchResult(
            snippetText: $snippetText
            languageType: $languageType
          ) {
            matchTime
            scanTime
            snippetSize
            matchSize
            otcount
            snippetMatches {
              matchAssetId
              repositoryName
              repositoryOwner
              assetName
              matchPercent
              duplicates
              matchScore
              earliestRelease {
                releaseDate
                releaseName
              }
              latestRelease {
                releaseDate
                releaseName
              }
              earliestReleaseLicenses {
                licenseId
                licenseName
              }
              latestReleaseLicenses {
                licenseId
                licenseName
              }
              assetLicenses {
                licenseId
                licenseName
                licenseContext
              }
              repositoryLicenses {
                licenseId
                licenseName
              }
              copyrights {
                text
              }
            }
          }
        }
      `,
      'no-cache',
      { snippetText, languageType }
    );
  }

  // create ignored files setting
  saveIgnoredFiles(ignoredFiles: IgnoredFiles) {
    const ignoredFilesRequest = new IgnoredFilesRequestInput(
      ignoredFiles.objectId,
      ignoredFiles.type,
      ignoredFiles.level,
      ignoredFiles.pattern
    );
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($ignoredFilesRequest: IgnoredFilesRequestInput) {
          saveIgnoredFiles(ignoredFilesRequest: $ignoredFilesRequest) {
            objectId
            type
            level
            pattern
          }
        }
      `,
      { ignoredFilesRequest }
    );
  }

  // get ignore files setting
  scpProjectIgnoredFilesSettings(scpProjectId: string) {
    return this.coreGraphQLService.coreGQLReq<IgnoredFileSettingQuery>(
      gql`query {
                scpProjectIgnoredFilesSettings(scpProjectId: "${scpProjectId}") {
                  objectId,
                  type,
                  level,
                  pattern
                }
            }`,
      'no-cache'
    );
  }

  // get ignore files setting
  getIgnoredFiles(projectId: string, entityId: string) {
    return this.coreGraphQLService.coreGQLReq<IgnoredFileSettingQuery>(
      gql`query {
                getIgnoredFiles(projectId: "${projectId}", entityId: "${entityId}") {
                  objectId,
                  type,
                  level,
                  pattern
                }
            }`,
      'no-cache'
    );
  }

  // update ignore files setting
  updateIgnoredFiles(ignoredFiles: IgnoredFiles) {
    const ignoredFilesRequest = new IgnoredFilesRequestInput(
      ignoredFiles.objectId,
      ignoredFiles.type,
      ignoredFiles.level,
      ignoredFiles.pattern
    );
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($ignoredFilesRequest: IgnoredFilesRequestInput) {
          updateIgnoredFiles(ignoredFilesRequest: $ignoredFilesRequest) {
            objectId
            type
            level
            pattern
          }
        }
      `,
      { ignoredFilesRequest }
    );
  }

  // remove ignore files setting
  removeIgnoredFiles(ignoredFiles: IgnoredFiles) {
    const ignoredFilesRequest = new IgnoredFilesRequestInput(
      ignoredFiles.objectId,
      ignoredFiles.type,
      ignoredFiles.level,
      ignoredFiles.pattern
    );
    return this.coreGraphQLService.coreGQLReqForMutation(
      gql`
        mutation ($ignoredFilesRequest: IgnoredFilesRequestInput) {
          removeIgnoredFiles(ignoredFilesRequest: $ignoredFilesRequest) {
            objectId
            type
            level
            pattern
          }
        }
      `,
      { ignoredFilesRequest }
    );
  }

  createScanWorkflow(scanWorkflowRequest: ScanWorkflowRequest) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`query {
          createScanWorkflow(
            appName: "${scanWorkflowRequest.appName}", 
            repoUrl: "${scanWorkflowRequest.repoUrl}", 
            repoOwner: "${scanWorkflowRequest.repoOwner}", 
            repository: "${scanWorkflowRequest.repository}", 
            branch: "${scanWorkflowRequest.branch}", 
            repoType: "${scanWorkflowRequest.repoType}", 
            entityId: "${scanWorkflowRequest.entityId}", 
            triggerEvent: [${scanWorkflowRequest.triggerEvent}],
            trxUrl: "${scanWorkflowRequest.trxUrl}"
            ) {
              appName,
              errorMessage,
              success,
              owner,
              repository,
              secretCreated
            }
        }`,
      'no-cache'
    );
  }

  getScanAssetsForTree(
    scanId: string,
    parentScanAssetId: string,
    fetchPolicy?: FetchPolicy
  ) {
    const parentId: string =
      parentScanAssetId.length > 0
        ? '(parentScanAssetId: "' + parentScanAssetId + '")'
        : '';

    fetchPolicy = fetchPolicy !== undefined ? fetchPolicy : 'no-cache';

    return this.coreGraphQLService.coreGQLReq<ScanQuery>(
      gql`
        query {
          scan(scanId:"${scanId}") {
            scanAssetsTreeByParent${parentId} {
                  name,
                  scanAssetId,
                  workspacePath,
                  scanAssetType,
                  projectId
            }
          }
        }
      `,
      fetchPolicy
    );
  }

  originTracingStatus(scanId: string, orgId: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`mutation {
        originTracingStatus(orgId: "${orgId}", scanId: "${scanId}") {
                assetCount,
                otQueueStatus,
                traceCount,
                ETC
              }
            }`,
      'no-cache'
    );
  }

  latestProjectScan(projectId: string) {
    return this.coreGraphQLService.coreGQLReq<any>(
      gql`
        query {
          project(projectId:"${projectId}") {
            latestScan {
              errorMsg
              branch
              status
              otMetaData
              orgId
              created
              version
              tag
              scanId
              log
              status
              versionHash
              vulnerabilityCount
            }
          }
        }
      `,
      'no-cache'
    );
  }
}
