<ngb-alert *ngIf="showAlert" [type]="'warning'" (close)="showAlert = false"
  >SCM related actions are not available for this project scan.</ngb-alert
>
<div class="text-center center-table-progressbar" *ngIf="!scanAssetDetails">
  <mat-progress-spinner
    strokeWidth="5"
    [diameter]="40"
    [mode]="'indeterminate'"
  >
  </mat-progress-spinner>
</div>
<div *ngIf="scanAssetDetails">
  <div *ngIf="scanOpenSourceProject">
    This is open source project '{{ scanOpenSourceProject.owner }}/{{
      scanOpenSourceProject.name
    }}' and the assets were not scanned.
    <br />
    <a href="{{ scanOpenSourceProject.repoWebsite }}">{{
      scanOpenSourceProject.repoWebsite
    }}</a>
  </div>
  <div *ngIf="!scanOpenSourceProject">
    <div style="float: left; clear: both">
      <ng-template pTemplate="caption">
        <div
          style="
            text-align: left;
            float: left;
            width: 50px;
            height: 50px;
            margin-right: 10px;
          "
        >
          header
        </div>
      </ng-template>
    </div>

    <div class="row">
      <div class="col-md-12 file-path">
        <span *ngIf="story?.length > 0">Path: </span>
        <span *ngFor="let bread of story; let i = index">
          <span
            [ngClass]="{ 'text-primary': i != story.length - 1 }"
            (click)="goBackfromBreadcum(bread.id, i)"
            >{{ bread.name }}</span
          >
          <span *ngIf="i != story.length - 1"> / </span>
        </span>
      </div>
    </div>
    <!-- 
    <div
      class="text-center center-table-progressbar"
      *ngIf="pagingState.isPaginationDisabled"
    >
      <mat-progress-spinner
        strokeWidth="5"
        [diameter]="40"
        [mode]="'indeterminate'"
      >
      </mat-progress-spinner>
    </div> -->
    <app-new-assets-table
      [scanAssetsTree]="scanAssetDetails?.scanAssetsTree"
      [story]="story"
      [columnsFilter]="columnsFilter"
      (filterChange)="onFilterColumn($event)"
      (details)="gotoDetails($event)"
      (back)="goBack()"
      (updateList)="initData()"
      [loading]="isLoading || pagingState.isPaginationDisabled"
      [isScmActionsAvailableForScan]="
        scanAssetDetails.isScmActionsAvailableForScan
      "
    ></app-new-assets-table>
    <!-- <app-assets-table
      [scanAssetsTree]="scanAssetDetails?.scanAssetsTree"
      [story]="story"
      [columnsFilter]="columnsFilter"
      (filterChange)="onFilterColumn($event)"
      (details)="gotoDetails($event)"
      (back)="goBack()"
      [loading]="isLoading || pagingState.isPaginationDisabled"
    >
    </app-assets-table> -->

    <div
      [class.paginator-hidden]="
        !pageInfo?.hasNextPage && !pageInfo?.hasPreviousPage
      "
    >
      <mat-paginator
        [hidePageSize]="false"
        [disabled]="pagingState.isPaginationDisabled"
        [pageSize]="pagingState.pageSize"
        [pageSizeOptions]="pagingState.pageSizeOptions"
        [length]="scanAssetDetails?.scanAssetsTree?.totalCount"
        (page)="changePage($event)"
      >
      </mat-paginator>
    </div>
  </div>
</div>
