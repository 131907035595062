import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { debounceTime, finalize, map, take } from 'rxjs/operators';

import { ScanOpenSourceProject } from '@app/models';

import { Paginated } from '@app/base/paginated.base';
import { GraphQLPage } from '@app/models/paging';
import { CoreHelperService } from '@app/services/core/core-helper.service';
import { FilterStateService } from '@app/services/core/filter-state.service';
import { UserPreferenceService } from '@app/services/core/user-preference.service';
import { ProjectService } from '@app/services/project.service';
import { Subscription } from 'rxjs';
import { PagingService } from '@app/services/paging.service';

@Component({
  selector: 'app-scanassets',
  templateUrl: './scanassets.component.html',
  styleUrls: ['./scanassets.component.scss'],
})
export class ScanAssetsComponent
  extends Paginated
  implements OnInit, OnChanges
{
  @Input() public isShowCompositeData: boolean;
  @Input() public scanId: string;
  @Output() public isAssetStory: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild(MatPaginator) public matPaginator: MatPaginator;

  public scanAssetDetails: any;
  public scanOpenSourceProject: ScanOpenSourceProject;
  public columnsFilter = new Map();
  public timeOut;
  public timeOutDuration: number = 1000;
  public parentScanAssetId: string = '';
  public story = [];
  sub: Subscription;
  public showAlert: boolean;
  public isLoading: boolean;

  constructor(
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private coreHelperService: CoreHelperService,
    public userPreferenceService: UserPreferenceService,
    private saveFilterStateService: FilterStateService,
    private pagingService: PagingService
  ) {
    super(userPreferenceService, 'Project', 'Assets');
  }

  ngOnInit() {
    this.columnsFilter = this.saveFilterStateService.getFilter('assets');
    this.story = [];
    this.isAssetStory.emit(false);
    this.checkScanDataExists();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['scanId']) {
      this.scanAssetDetails = undefined;
      this.story = [];
      this.parentScanAssetId = '';
      this.initData();
    }
  }

  // Checking if scanObject is already passed from parent component if not then get data from server To make it re-use component
  checkScanDataExists() {
    const preferenceDetails =
      this.userPreferenceService.getPanelDetailByModule('Project');
    const projectId = this.route.snapshot.paramMap.get('projectId');
    if (
      !!preferenceDetails &&
      !!preferenceDetails.assetPreferences &&
      preferenceDetails.assetPreferences.length >= 1 &&
      !!preferenceDetails.assetPreferences.find(
        (f) => f.projectId === projectId
      ) &&
      !!preferenceDetails.assetPreferences.find(
        (s) => s.currrentScanId === this.scanId
      )
    ) {
      const prefData = preferenceDetails.assetPreferences.find(
        (f) => f.projectId === projectId
      );
      this.scanAssetDetails = prefData.currentAssetDetails;
      this.story = prefData.currentStory;
      this.parentScanAssetId = !!prefData.parentScanAssetId
        ? prefData.parentScanAssetId
        : this.parentScanAssetId;
      this.initData();
    } else {
      this.initData();
    }
  }

  goBack() {
    this.parentScanAssetId = this.story.pop().id;
    this.refreshAssetListHelper();
  }

  gotoDetails(scanAsset) {
    if (scanAsset.node.scanAssetType === 'DIR') {
      this.story.push({
        id: this.parentScanAssetId,
        originalName: scanAsset.node.name,
        name: this.breadcumSetting(scanAsset),
      });
      this.isAssetStory.emit(true);
      this.parentScanAssetId = scanAsset.node.scanAssetId;
      this.reload();
    }
    // else {
    //   if (scanAsset.node.matchCount >= 1) {
    //     this.isAssetStory.emit(false);
    //     const sAssetId = scanAsset.node.scanAssetId;
    //     const entityId = this.route.snapshot.paramMap.get('entityId');
    //     const projectId = this.route.snapshot.paramMap.get('projectId');
    //     const url =
    //       'security/entity/' +
    //       entityId +
    //       '/project/' +
    //       projectId +
    //       '/scan/' +
    //       this.scanId +
    //       '/scanasset/' +
    //       sAssetId;
    //     this.router.navigate([decodeURIComponent(url)], {
    //       queryParams: {
    //         isScmActionsAvailableForScan:
    //           this.scanAssetDetails.isScmActionsAvailableForScan,
    //       },
    //     });
    //   }
    // }
  }

  reload() {
    this.initData();
  }

  filterOnlyAssetsIfFilterActivated() {
    if (
      !!this.scanAssetDetails &&
      this.scanAssetDetails.scanAssetsTree.edges.length >= 1 &&
      this.columnsFilter.size >= 1
    ) {
      this.scanAssetDetails.scanAssetsTree.edges =
        this.scanAssetDetails.scanAssetsTree.edges.filter(
          (asset) => asset.node.scanAssetType !== 'DIR'
        );
    }
  }

  /**
   * Filter by column
   *
   * @param column column name
   * @param event input event
   * @param idElement element ID
   */
  onFilterColumn({ columnsFilter }) {
    this.columnsFilter = columnsFilter;
    this.saveFilterStateService.saveFilter('assets', this.columnsFilter);
    this.matPaginator.pageIndex = 0;
    this.initData();
  }

  goBackfromBreadcum(id, currentIndex) {
    if (currentIndex !== this.story.length - 1) {
      const startIndexToRemove = currentIndex + 1;
      this.parentScanAssetId = this.story[startIndexToRemove].id;
      this.story.splice(
        startIndexToRemove,
        this.story.length - startIndexToRemove
      );
      this.refreshAssetListHelper();
    }
  }

  refreshAssetListHelper() {
    if (!this.story || this.story.length === 0) {
      this.isAssetStory.emit(false);
    }
    this.reload();
  }

  getSummationOfEmbeded(array: any[]) {
    return array.map((f) => f.node.percentMatch).reduce((a, b) => a + b, 0);
  }

  private makeFilterMapForService() {
    let filterString = '';
    this.columnsFilter.forEach((val, key) => {
      filterString += key + ':' + val + ',';
    });
    return filterString;
  }

  changePage(pageEvent: PageEvent) {
    if (this.pagingState.isPaginationDisabled) {
      return;
    }
    const graphQLPage: GraphQLPage = this.pagingService.calculateGraphQLPage(
      this.pagingScope,
      this.pagingState,
      pageEvent,
      this.matPaginator,
      this.pageInfo
    );

    this.initData(graphQLPage);
  }

  // initializing data
  public initData(graphQLPage?: GraphQLPage): void {
    this.isLoading = true;
    this.pagingState.isPaginationDisabled = true;
    if (this.sub) {
      this.sub.unsubscribe();
    }

    if (graphQLPage === undefined) {
      graphQLPage = new GraphQLPage(this.pagingState.pageSize);
    }

    this.sub = this.projectService
      .getScanAssets(
        this.scanId,
        this.parentScanAssetId,
        this.makeFilterMapForService(),
        graphQLPage,
        undefined,
        this.isShowCompositeData
      )
      .pipe(
        debounceTime(200),
        take(1),
        map((result) => result.data.scan),
        finalize(() => (this.isLoading = false))
      )
      .subscribe(
        (asset) => {
          this.showAlert = !asset.isScmActionsAvailableForScan;
          this.pagingState.isPaginationDisabled = false;
          this.scanAssetDetails = asset;
          this.pageInfo = asset.scanAssetsTree.pageInfo;
          if (!!localStorage.getItem('UPDATED_SCAN_ASSETID')) {
            const scanAssetIdLists = JSON.parse(
              localStorage.getItem('UPDATED_SCAN_ASSETID')
            );
            this.scanAssetDetails.scanAssetsTree.edges.forEach((element) => {
              if (
                !!scanAssetIdLists.find(
                  (f) =>
                    f.scanAssetId === element.node.scanAssetId &&
                    this.scanId === f.scanId
                )
              ) {
                element.node.attributionStatus = scanAssetIdLists.find(
                  (f) => f.scanAssetId === element.node.scanAssetId
                ).status;
              }
            });
          }
          this.filterOnlyAssetsIfFilterActivated();
          this.setUserPreferencesDetailsForAseets();
        },
        () => {
          this.pagingState.isPaginationDisabled = false;
        }
      );
  }

  private breadcumSetting(scanAsset) {
    if (!!this.story && this.story.length >= 1) {
      const lastRecord = this.story[this.story.length - 1].originalName;
      if (!!lastRecord) {
        const diffrence = this.coreHelperService.getDifferencebetweenStrings(
          lastRecord,
          scanAsset.node.name
        );
        return diffrence.replace('/', '');
      } else {
        return scanAsset.node.name;
      }
    } else {
      return scanAsset.node.name;
    }
  }

  private setUserPreferencesDetailsForAseets() {
    const projectId = this.route.snapshot.paramMap.get('projectId');
    this.userPreferenceService.settingUserPreference(
      'Project',
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      {
        currentStory: this.story,
        currentAssetDetails: this.scanAssetDetails,
        projectId,
        parentScanAssetId: this.parentScanAssetId,
        currrentScanId: this.scanId,
      }
    );
  }
}
