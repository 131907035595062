export class LoadEntity {
  static type = '[Entity] LoadEntity';

  constructor(public entityId: string) {}
}

export class LoadProjectsNextPage {
  static type = '[Entity] LoadProjectsNextPage';
}

export class GetAuditSummaryForEntity {
  static type = '[Entity] GetAuditSummaryForEntity';

  constructor(public entityId: string) {}
}

export class GetScanHistory {
  static type = '[Entity] GetScanHistory';

  constructor(public projectId: string) {}
}

export class LoadNonScpProjects {
  static type = '[Entity] LoadNonScpProjects';

  constructor(public entityId: string) {}
}

export class ImportProjectToScp {
  static type = '[Entity] ImportProjectToScp';

  constructor(public rootProjectId: string, public scpProjectId?: string) {}
}

export class ClearEntity {
  static type = '[Entity] ClearEntity';

  constructor() {}
}

export class SetCurrentPathData {
  static type = '[Entity] SetCurrentPathData';
  constructor(
    public currentPathData: {
      entityId: string;
      scanId: string;
      assetPath: string;
      projectName: string;
    }
  ) {}
}
export class ChangeTab {
  static type = '[Entity] ChangeTab';

  constructor(public tab: string) {}
}

export class DeleteProjectEntry {
  static type = '[Entity] DeleteProjectEntry';

  constructor(public projectId: string) {}
}

export class LastScan {
  static type = '[Entity] LastScan';

  constructor(public projectId: string) {}
}

export class IgnoredFilesList {
  static type = '[Entity] IgnoredFilesList';
}

export class IgnoreFile {
  static type = '[Entity] IgnoreFile';

  constructor(public ignoredAsset) {}
}

export class UnignoreFile {
  static type = '[Entity] UnignoreFile';

  constructor(public ignoredAsset) {}
}
